.App {
  text-align: center;
  background: no-repeat center center fixed;
  background-size: 60%;
  background-color: black;  
  min-height: 100vh;
}


.buttons {
  margin-top: 33%;
}

.aboutButton{
  margin-top: 10%;
}



.header {
  color: white;
  font-size: 50px;
}